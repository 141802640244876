$size: 80px;
$mSize: 80px;

.container {
  display: flex;
  justify-content: space-between;
  height: $size;
  background-color: #000;
  .headerMenu {
    display: flex;
  }
}

.logoContainer {
  width: $size;
  height: $size;
  background-color: #001AFF;
  padding: 16px;
}

.inner {
  padding: 11px 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  img {
      height: 31px;
      margin-bottom: 5px;
  }
  .nav {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 40px;
    > * {
      line-height: 1;
      font-weight: 500;
      color: #fff;
    }
    .active {
      position: relative;
      z-index: 1;
      &:after {
        content: '';
        position: absolute;
        width: calc(100% + 16px);
        left: -8px;
        height: 12px;
        background-color: #001AFF;
        z-index: -1;
        bottom: -4px;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .nav {
      display: none;
      }
  }
  
}

.mobileMenu {
  display: none;
  z-index: 1;
  img.icon {
    width: 2rem;
    height: 2rem;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    height: $mSize;
    .headerMenu {
      width: 100%;
    }
  }
  .logoContainer {
    width: $mSize;
    height: $mSize;
    padding: 16px;
  }
  .inner {
    padding: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    img {
      height: 2rem;
    }
  }
  .mobileMenu {
    display: block;
  }
  ul.listMenu li a {
    color: #fff;
  }
}
