@keyframes slideout {
  from {
    left: 0;
  }
  to {
    left: -600px;
  }
}

.container {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 80px;
  display: flex;
  background-color: #000;
  z-index: 9999;
  max-width: 300px;
  h5 {
    font-size: 1rem;
  }
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;
  }
  .info {
    width: 100%;
    background-color: #001AFF;
    display: flex;
    align-items: center;
    padding: 0 16px;
    .text {
      font-weight: 700;
      line-height: 1;
      color: #fff;
      font-size: 1rem;
      strong {
        position: relative;
        z-index: 1;
        &:after {
          content: '';
          position: absolute;
          width: calc(100% + 6px);
          left: -3px;
          height: 3px;
          background-color: #000;
          z-index: -1;
          bottom: 2px;
        }
      }
    }
  }
  &.connect {
    flex-direction: column;
    padding: 10px;
    .infoText {
      font-size: 0.8rem;
      max-width: 250px;
      line-height: 1.2;
      text-align: left;
      color: #fff;
    }
  }
  &.connected {
    background-color: #001AFF;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 16px;
    width: 168px;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-name: slideout;
    animation-duration: 1s;
    animation-delay: 3s;
    .logoAll {
      margin-bottom: 10px;
    }
    .text {
      font-weight: 700;
      line-height: 1.16;
      color: #fff;
    }
  }
}

@media only screen and (max-width: 600px) {
  .container {
    h5 {
      color: #fff;
      font-size: 1.3rem;
    }
    &.connect {
      padding: 3px 8px;
      p {
        line-height: 1.3;
      }
    }
    &.connected {
      justify-content: center;
      img {
        display: none;
      }
      width: 255px;
      height: 45px;
      h5.text {
        line-height: 17px;
      }
    }
    .info {
      height: 90px;
      width: 100%;
      a {
        font-size: 1rem;
      }
      .text {
        strong {
          &:after {
            width: calc(100% + 4px);
            left: -2px;
            bottom: -1px;
          }
        }
      }
    }
    img {
      display: none;
    }
    max-width: calc(100% - 45px);
    width: 100%;
    height: 90px;
  }
}
