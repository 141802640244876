.buttons {
  margin: 1rem;
  button.addNetworkBtn {
    width: 195px;
    img.iconBtn {
      height: 24px;
      margin-top: 0;
      width: auto;
    }
    .btnText {
      display: inline-block;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 415px) {
  .buttons {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
}
