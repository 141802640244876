.itemBar {
  & > div {
    display: flex;
    align-items: baseline;
    .note, .value {
      font-weight: 700;
    }
    .value {
      margin-right: 10px;
      span[class*="wordBreakAll"] {
        word-break: break-all;
      }
      small {
        font-size: 0.75rem;
      }
      &.red {
        color: red;
      }
    }
  }
}

.valueLink {
  margin-right: 0;
}


@media only screen and (max-width: 991px) {
  .title {
    display: none;
  }
  div[class*="Mui-expanded"] {
    .title {
      display: inline;
    }
  }
}

