@font-face {
  font-family: "Fontawesome";
  font-weight: 500;
  src: url("../../Resources/Fonts/fa-solid-900.woff") format("truetype");
}

@font-face {
  font-family: "Fontawesome";
  font-weight: 700;
  src: url("../../Resources/Fonts/fa-regular-400.woff") format("truetype");
}

@font-face {
  font-family: "Fontawesome";
  font-weight: 900;
  src: url("../../Resources/Fonts/fa-solid-900.woff") format("truetype");
}

body {
  min-height: 100vh;
}

.instructionsMenu {
  .itemMenu {
    border-bottom: 1px solid #C2C2C2;
    padding: 10px;
    display: grid;
    align-items: center;
    grid-template-columns: 100px 1fr;
  }
  .itemMenu:last-child {
    border: none;
  }
  a {
    display: inline-block;
    text-align: left;
    width: 100%;
    margin: 0;
    padding-left: 3.8rem;
    text-transform: uppercase;
    font-size: 1.25rem;
  }
  .itemIcon img {
    width: 86px;
    height: 86px;
  }
}

.faLightBulb {
  color: #F88E00;
  font-size: 4rem;
  line-height: 1;
  &:before {
    font-family: "Fontawesome", sans-serif;
    content: "\f0eb";
  }
}

.faInfoCircle {
  color: #8EC1EF;
  font-size: 4rem;
  line-height: 1;
  &:before {
    font-family: "Fontawesome", sans-serif;
    content: "\f05a";
  }
}

.faQuestionCircle {
  color: #E7C9E7;
  font-size: 4rem;
  line-height: 1;
  &:before {
    font-family: "Fontawesome", sans-serif;
    content: "\f059";
  }
}

.faExclamationTriangle {
  color: #FF4434;
  font-size: 4rem;
  line-height: 1;
  &:before {
    font-family: "Fontawesome", sans-serif;
    content: "\f071";
  }
}

.farFaBell {
  color: #FFF;
  font-size: 4rem;
  line-height: 1;
  text-align: center;
  margin-top: 50px;
  &:before {
    font-family: "Fontawesome", sans-serif;
    content: "\f0f3";
    border: 1px #FF4434 solid;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    display: inline-block;
    padding: 1.2rem;
    background-color: #FF4434;
  }
}

.instructionPage, .instructionsMenu {
  margin-top: 60px;
  padding: 15px 15px 100px 15px;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  h1 {
    text-transform: uppercase;
    font-size: 2.14rem;
    text-align: center;
    margin: 20px auto 70px;
    color: #000;
    font-weight: 600;
  }
  h3 {
    font-size: 1.7rem;
    text-align: center;
    margin-top: 75px;
    margin-bottom: 30px;
  }
  h4 {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 10px 0;
  }
}

.infobox {
  .content {
    display: flex;
    align-items: flex-start;
    margin: 20px;
    img {
      margin-top: 5px;
      width: 67px;
      height: auto;
    }
    .infoboxNum {
      p {
        font-size: 4rem;
        color: #828893;
        line-height: 1.1;
      }
    }
    a {
      text-overflow: ellipsis;
      max-width: calc(100vw - 140px);
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom
    }
    ul a {
      max-width: calc(100vw - 180px);
    }
  }
}

.infobox, .infobox2 {
  b {
    display: inline-block;
    padding: 0 8px;
    background-color: black;
    color: lime;
    word-break: break-all;
  }
  u {
    color: #F95858
  }
}

.infoboxContent {
  padding-left: 15px;
}

div.elementorSection {
  background-color: #EEF5FF;
  margin-top: 40px;
  // padding: 10px;
  h4, p {
    margin-left: 15px;
    margin-right: 15px;
  }
  ul {
    list-style: none;
  }
  .content {
    margin: 0 0 0 16px;
  }
  & > div.itemSection, & > div.backgroundColor2 {
    padding-bottom: 25px;
  }
}

.backgroundColor2 {
  background-color: #FFFAEA
}


.bigBorder {
  border: 22px solid #ac9e9e;
  width: 100%;
}

.infoSectionRow > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pageSmartcontractsContent {
  & > div {
    margin-bottom: 45px;
    a {
      display: inline-block;
      padding: 0 8px;
      background-color: black;
      color: lime;
      word-break: break-all;
      font-weight: 600;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.image {
  text-align: center;
  margin: 20px 5px;
  img {
    max-width: 90vw;
  }
}


@media only screen and (max-width: 575px) {
  .instructionsMenu {
    a {
      padding-left: .5rem;
    }
  }
  .infobox {
    .content {
      margin: 20px 0;
    }
  }
}
