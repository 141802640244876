.container {
  padding: 48px 32px;
  display: grid;
  // grid-template-columns: auto 660px;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: dense;
  grid-column-gap: 30px;
  // overflow: hidden;
  align-items: start;
  &.noPaddingTop {
    padding-top: 0;
  }
}

.sections {
  overflow: hidden;
}

.left {
  .top {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 40px;
    justify-content: flex-start;
    margin-bottom: 45px;
  }
}

.right {
  min-height: 100px;
  position: relative;
  .top {
    padding: 32px 64px 48px;
    justify-content: flex-start;
    display: grid;
    grid-auto-flow: column;
    column-gap: 32px;
    &.smallTop {
      padding-top: 0px;
    }
    .partClusterTitle {
      text-transform: uppercase;
      font-weight: bold !important;
    }
    .dots {
      display: grid;
      grid-auto-flow: column;
      column-gap: 5px;
      align-items: center;
      padding-top: 3px;
      .dot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #000;
      }
    }
  }
  .topCluster {
    display: grid;
    grid-auto-flow: row;
    padding: 32px 64px 0px;
  }
}

.section {
  margin-bottom: 40px;
}

.loaderSmall {
  margin-top: 16px;
}

.title {
  color: #A7A7A7;
  text-transform: uppercase;
}


.right {
  background-color: #fff;
  .bottom {
    position: relative;
    margin-top: 40px;
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 40px;
    justify-content: flex-end;
    background-color: #000;
    padding: 48px 64px;
    .cancel {
      color: #fff;
      text-transform: uppercase;
    }
  }
}

.form {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 34px;
  padding: 0 64px;
  &.formDisabled {
    padding-bottom: 80px;
  }
  .gotToRow {
    display: grid;
    grid-template-columns: auto 100px;
    grid-column-gap: 24px;
    align-items: center;
  }
  .status {
    .row {
      display: grid;
      padding-top: 6px;
      grid-auto-flow: column;
      grid-column-gap: 24px;
      justify-content: flex-start;
    }
    .label {
      font-weight: bold !important;
    }
  }
}

.largeInput {
  font-size: 2.25rem !important;
  line-height: 3.125rem !important;
  font-weight: bold !important;
}

.autocompleteInput {
  padding-top: 10px !important;
  padding-bottom: 20px !important;
}

.autocompleteTag {
  margin-bottom: 12px !important;
}

.autocompleteEnd {
  display: none;
}

.active {
  font-weight: 700 !important;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    width: calc(100% + 8px);
    left: -4px;
    height: 2px;
    background-color: #000;
    z-index: 0;
    bottom: -4px;
  }
}

.clusters {
  display: grid;
  grid-template-columns: repeat(auto-fill, 160px);
  grid-gap: 24px;
  margin-top: 24px;
}

.loader {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.clusterPreviewImage {
  max-height: 176px;
  margin-top: 12px;
}

.popupItems {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: row;
  &.popupItemsDelete {
    display: block;
  }
  .buttonBase {
    padding: 10px 16px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    width: 144px;
    height: 144px;
    * {
      font-weight: bold;
    }
  }
  & > button > p {
    text-align: left;
  }
  .black {
    @extend .buttonBase;
    background-color: #000;
    border: 1px solid #000;
  }
  .blue {
    @extend .buttonBase;
    background-color: #001AFF;
    border: 1px solid #001AFF;
  }
  .red {
    @extend .buttonBase;
    background-color: #E60029;
    border: 1px solid #E60029;
  }
  .vanilla {
    @extend .buttonBase;
    background-color: #DAA352;
    border: 1px solid #DAA352;
  }
  .extended {
    grid-column: 2 / span 2;
  }
}

.popupItemLink {
  .buttonBase {
    padding: 10px 16px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100px;
    height: 100px;
    * {
      font-weight: bold;
    }
  }
  .black {
    @extend .buttonBase;
    background-color: #000;
    border: 1px solid #000;
  }
}

.popperContainer {
  z-index: 1;
  & > div {
    margin-left: 50px;
  }
}

.popperContainer.infoContainer {
  .buttonBase {
    width: 100px;
    height: 100px;
    font-weight: bold;
  }
  & > div {
    margin-left: -5px;
  }
}

.closeIcon {
  text-align: right;
  padding: 15px 15px 0 0;
  cursor: pointer;
}

.editAreaAndClusterSection {
  position: sticky;
  overflow-y: scroll;
  top: 10px;
  height: 100vh;
  &::-webkit-scrollbar {
    width: 0;
  }
  scrollbar-width: none;
}

.content {
  padding: 25px 64px;
}

@media only screen and (max-width: 992px) {
  .container {
    // grid-template-columns: 160px auto;
    grid-column-gap: 20px;
  }
  .form {
    .gotToRow {
      grid-template-columns: none;
    }
  }
}

@media only screen and (max-width: 992px) {
  .popupItems {
    .buttonBase {
      width: 100px;
      height: 100px;
    }
  }
  .closeIcon {
    position: fixed;
    right: 0;
    z-index: 10;
  }
  .editAreaAndClusterSection {
    z-index: 2;
    top: 0;
    left: 0;
    position: absolute;
  }
  .sections {
    overflow: visible;
  }
  .container {
    display: block;
    padding-bottom: 0;
    .clusters {
      justify-content: space-evenly;
    }
  }
  .right {
    // left: -32px;
    width: 100vw;
    .bottom {
      padding: 20px;
      justify-items: center;
      grid-template-columns: 1fr 1fr;
    }
  }
  .dialogWindow > div > div {
    width: 100%;
  }
  .dialogButtons {
    button {
      min-width: 48%;
    }
  }
}

@media only screen and (max-width: 576px) {
  .container {
    padding: 48px 14px;
  }
}
