.main {
  color: #ffffff;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  p {
    margin: 4px 8px 4px 0;
    line-height: 1rem;
    font-size: 0.5rem;
    border: 1px solid #fff;
    vertical-align: middle;
    padding: 0 0.5rem;
    white-space: nowrap;
    .indicator {
      display: inline-block;
      height: 0.4rem;
      width: 0.4rem;
      border-radius: 50%;
      margin-left: 5px;
    }
    .indicator.error {
      background-color: red;
    }
    .indicator.success {
      background-color: #05e005;
    }
  }
  .pending {
    display: flex;
    background-color: red;
    border-color: red;
    align-items: center;
    margin-right: 8px;
    p {
      border: none;
      margin: 0;
    }
    .loaderSmall {
      margin: 0  5px 0 0;
      display: flex;
      align-items: center;
    }
  }
}

.main.mobile {
  margin-left: 2rem;
  margin-top: 2rem;
}

@media only screen and (max-width: 990px) {
  .main.desctop {
    position: absolute;
    top: 15px;
    right: 8px;
  }
}

@media only screen and (max-width: 600px) {
  .main.desctop {
    display: none;
  }
}